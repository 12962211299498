import React, {useCallback, useEffect, useRef, useState, Fragment} from 'react';
import styled from 'styled-components';
import {Spin, message, List, Alert, Tag, Input} from 'antd';
import JSONTree from 'react-json-tree';
import {Edit} from '@styled-icons/material/Edit';
import {RemoveRedEye} from '@styled-icons/material/RemoveRedEye';
import {Refresh} from '@styled-icons/material/Refresh';
import {Delete} from '@styled-icons/material/Delete';
import {ContentCopy} from '@styled-icons/material/ContentCopy';
import {useOutlet} from 'reconnect.js';

import {pricingTypes, emptySpec} from '../../pricingTypes';
import ApiFactory from '../../ApiSpec';
import SpecForm from './SpecForm';
import {generate, searchProductType, highlight} from '../../Utils/SpecUtils';
import Block from '../../Widgets/Block';
import Row from '../../Widgets/Row';
import Button from '../../Widgets/Button';
import BackButton from '../../Components/BackButton';
import Tabs from '../../Widgets/Tabs';
import {errorHandler} from '../../errors';

const appConfig = require('../../data.json');

const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

let pricingTypeOptions = pricingTypes
  .filter((x) => x !== 'custom')
  .map((x) => ({
    label: x,
    value: x,
  }));

async function retry({action, checkPass, callback}) {
  const msg = {
    success: '已取得更新版的商品規格！',
    failure: '未取得更新版的商品規格',
  };
  const _count = 5;
  let count = 5;
  while (count-- > 0) {
    console.log('count', count);
    let resp = await action();
    if (checkPass(resp)) {
      console.log('version', resp.version);
      callback(resp);
      message.success(msg.success);
      count = _count;
      break;
    } else {
      message.warning(
        msg.failure +
          (count <= 0
            ? '，已嘗試' + _count + '次，請過一段時間再嘗試刷新'
            : '，將再次嘗試取得...'),
      );
    }
  }
}

export default function Editor({}) {
  const [pricing_type, setPricingType] = useState(pricingTypeOptions[0].value);
  const [loading, setLoading] = useState(false);
  const [json_obj, setJsonObj] = useState(null);
  const [edited, _setEdited] = useState([]);
  const [selected, setSelected] = useState(null);
  const [spec, setSpec] = useState(emptySpec);
  const api = useRef(
    new ApiFactory({
      apiHost: appConfig.endpoint.productSpecUploadUrl,
      bucketHost: appConfig.endpoint.productSpecUrl,
    }),
  ).current;
  const mounted = useRef(false);
  const disabled = edited.length > 0;
  let preVersion = useRef();
  const [token] = useOutlet('jwtToken');
  const [actions] = useOutlet('actions');

  const getSpec = useCallback(async () => {
    try {
      let spec = await api.getSpec();
      setSpec(spec);
      return {version: spec.version};
    } catch (error) {
      message.error('取得最新規格錯誤！');
    }
  });

  const onUpdateBuildHook = useCallback(async () => {
    actions.setLoading(true);
    try {
      if (window.confirm('確定要更新前台？')) {
        await actions.rebuild();
        message.success('更新成功，前台需要部署時間，請稍後查看。');
      }
    } catch (err) {
      await actions.getMicroServiceToken();
      errorHandler(err);
    }
    actions.setLoading(false);
  }, [actions]);

  const updateVersion = (version) => {
    if (version !== preVersion.current) {
      preVersion.current = version;
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (token) {
        let spec = await getSpec();
        updateVersion(spec);
        mounted.current = true;
      }

      setLoading(false);
    })();
  }, [token]);

  function editProduct(product, isDelete = false) {
    let arr = spec[pricing_type] || [];
    let idx = arr.findIndex((x) => x.name === product.name);
    if (isDelete) {
      arr.splice(idx, 1); // delete
    } else if (idx >= 0) {
      arr.splice(idx, 1, product); // replace
    } else {
      arr.push(product); // add 1
    }
    setSpec((prev) => ({...prev, [pricing_type]: arr}));
  }

  function setEdited(product) {
    let idx = edited.indexOf(product.name);
    if (idx >= 0) {
      _setEdited((prev) => [...prev].splice(idx, 1, product.name)); // replace
    } else {
      _setEdited((prev) => [...prev, product.name]);
    }
  }

  const submit = async () => {
    let arr = spec[pricing_type] || [];
    if (window.confirm('確定要更新商品規格？ 成功上傳後將會更新前台網站！')) {
      setLoading(true);

      try {
        await api.uploadSpec({
          json: arr,
          type: pricing_type,
          token,
        });
        message.success('已成功更新商品規格！');

        await retry({
          action: getSpec,
          checkPass: (resp) => resp.version !== preVersion.current,
          callback: (resp) => {
            updateVersion(resp.version);
          },
        });
        _setEdited([]);
        // message.success("重新刷新商品規格");
      } catch (err) {
        if (err.status === 403) {
          message.warning('身份認證錯誤，正在重新取得認證，請再按一次儲存。');
          await actions.getMicroServiceToken();
        } else {
          message.error('儲存錯誤！');
        }
      }
    }

    setLoading(false);
  };

  return (
    <Wrapper>
      {' '}
      {json_obj ? (
        <Fragment>
          <Row style={{marginBottom: 20}}>
            <BackButton onClick={() => setJsonObj(null)} />
          </Row>
          <Block>
            <h2>瀏覽規格</h2>
            <div className="divider" />
            <div className="content">
              <div className="row">
                <h4>規格類型</h4>
                <div>{pricing_type}</div>
              </div>
              {mounted.current && json_obj && (
                <JSONTree
                  shouldExpandNode={false}
                  hideRoot={true}
                  data={json_obj}
                />
              )}
            </div>
            <div className="row">
              <div style={{flex: 1}}></div>
              <Button
                icon={
                  <ContentCopy
                    size={20}
                    color="#486c94"
                    style={{marginRight: 10}}
                  />
                }
                onClick={() => {
                  window.navigator.clipboard
                    .writeText(JSON.stringify(json_obj, null, 2))
                    .then(() => {
                      message.success('已成功複製到剪貼簿');
                    });
                }}>
                {' '}
                複製到剪貼簿{' '}
              </Button>
            </div>
          </Block>
        </Fragment>
      ) : selected ? (
        <Fragment>
          <Row style={{marginBottom: 20}}>
            <BackButton onClick={() => setSelected(null)} />
          </Row>

          <SpecForm
            data={selected}
            type={pricing_type}
            onEdit={(product) => {
              editProduct(product);
              setEdited(product);
              setSelected(null);
            }}
            onMount={() => setLoading(false)}
          />
        </Fragment>
      ) : (
        <Spin spinning={loading}>
          <Row style={{marginBottom: 20}}>
            <Tabs
              value={pricing_type}
              onChange={async (value) => {
                setPricingType(value);
                await delay(200);
                highlight('');
              }}
              options={pricingTypes
                .filter((p) => p !== 'custom')
                .map((p) => ({label: p, value: p, disabled: disabled}))}
            />

            <div style={{flex: 1}} />
            <Input.Search
              style={{width: 200, marginRight: 10}}
              placeholder="搜尋商品名稱"
              disabled={disabled}
              onSearch={async (value) => {
                if (value) {
                  let t = searchProductType(spec, value);
                  if (t) {
                    setPricingType(t);
                    message.success(`在${t}種類找到可能商品。`);
                  } else {
                    message.error('找不到此商品');
                  }
                }
                await delay(200);
                highlight(value);
              }}
              allowClear={true}
            />
            <Button
              type="primary"
              loading={loading}
              onClick={async () => {
                setLoading(true);
                await getSpec();
                setLoading(false);
              }}
              icon={
                <Refresh color="#fff" size={20} style={{marginRight: 5}} />
              }>
              刷新
            </Button>
            <Button onClick={() => onUpdateBuildHook()}>更新前台</Button>
          </Row>
          <Block>
            <h2>編輯器</h2>
            <div className="divider" />
            <div className="content">
              <div className="row">
                <h4>最新版本</h4>
                <div>{spec?.version || '-'}</div>
              </div>
              <div className="row">
                <h4>規格類型</h4>
                <div>{pricing_type}</div>
              </div>

              <div className="row">
                <List
                  size="small"
                  dataSource={spec && pricing_type ? spec[pricing_type] : []}
                  rowKey="name"
                  renderItem={(item) => (
                    <List.Item
                      actions={[
                        <Button
                          type="text"
                          style={{
                            border: 0,
                            backgroundColor: 'transparent',
                          }}
                          onClick={() => {
                            console.log('item', item);
                            setJsonObj(item);
                          }}>
                          <RemoveRedEye size={20} color="#486c94" />
                        </Button>,
                        <Button
                          type="text"
                          style={{
                            border: 0,
                            backgroundColor: 'transparent',
                          }}
                          onClick={() => {
                            setSelected({...item, name: ''});
                            message.success('複製商品規格成功。');
                          }}>
                          <ContentCopy size={20} color="#486c94" />
                        </Button>,
                        <Button
                          type="text"
                          style={{
                            border: 0,
                            backgroundColor: 'transparent',
                          }}
                          onClick={() => {
                            setLoading(true);
                            setSelected(item);
                          }}>
                          <Edit size={20} color="#3b9c45" />
                        </Button>,
                        <Button
                          type="text"
                          style={{
                            border: 0,
                            backgroundColor: 'transparent',
                          }}
                          onClick={() => {
                            editProduct(item, true);
                            setEdited(item);
                          }}>
                          <Delete size={20} color="#ff4d4f" />
                        </Button>,
                      ]}>
                      {edited.indexOf(item.name) >= 0 && (
                        <Tag color="volcano">編輯</Tag>
                      )}
                      <div className="text">{item.name}</div>
                    </List.Item>
                  )}
                />
              </div>
              {edited.length > 0 && (
                <Alert
                  message="有編輯過商品尚未儲存"
                  description={edited.join(', ')}
                  style={{marginBottom: 10}}
                  type="warning"
                  showIcon={true}
                />
              )}
              <div className="row">
                <Button
                  type="primary"
                  onClick={() => {
                    setSelected(generate({type: pricing_type}));
                  }}
                  style={{margin: '0 10px'}}>
                  新增
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setSelected(generate({type: pricing_type, empty: false}));
                  }}
                  style={{margin: '0 10px'}}>
                  新增預設商品
                </Button>
                <div style={{flex: 1}} />

                <Button
                  danger
                  onClick={async () => {
                    setLoading(true);
                    await getSpec();
                    _setEdited([]);
                    setLoading(false);
                  }}
                  disabled={edited.length <= 0}
                  style={{margin: '0 10px'}}>
                  取消
                </Button>
                <Button
                  type="primary"
                  onClick={submit}
                  danger
                  disabled={edited.length <= 0}
                  style={{margin: '0 10px'}}>
                  儲存
                </Button>
              </div>
              <div style={{marginBottom: 10, color: '#bbb'}}>
                # 確認上傳完成後，需等待網站建置幾分鐘。
              </div>
              <div style={{marginBottom: 10, color: '#bbb'}}>
                # 若無法加入購物車可能建置未完成，或規格有問題。
              </div>
            </div>
          </Block>
        </Spin>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  /* background-color: #bbb; */
  background-color: #f2f2f2;
  padding: 20px;
  min-height: 1000px;

  & .ant-list-item > .text.highlight {
    background-color: #e9ff32;
  }
`;
